if (typeof globalThis['METRO_DISABLE_BANNER'] === 'undefined') {
    console.info(`%c
███╗   ███╗███████╗████████╗██████╗  ██████╗     ██╗   ██╗██╗
████╗ ████║██╔════╝╚══██╔══╝██╔══██╗██╔═══██╗    ██║   ██║██║
██╔████╔██║█████╗     ██║   ██████╔╝██║   ██║    ██║   ██║██║
██║╚██╔╝██║██╔══╝     ██║   ██╔══██╗██║   ██║    ██║   ██║██║
██║ ╚═╝ ██║███████╗   ██║   ██║  ██║╚██████╔╝    ╚██████╔╝██║
╚═╝     ╚═╝╚══════╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝      ╚═════╝ ╚═╝                                                             
`, `color: #0080fe`)
}
